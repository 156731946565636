import {login, logout, getInfo} from '@/api/login'
import {getToken, setToken, removeToken} from '@/utils/auth'

const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    roles: [],
    permissions: []
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    }
  },

  actions: {
    // 登录
    Login({commit}, userInfo) {
      const username = userInfo.username.trim()
      const password = userInfo.password
      // const code = userInfo.code
      const code = ""
      const uuid = userInfo.uuid
      return new Promise((resolve, reject) => {
        login(username, password, code, uuid).then(res => {
          if(res.data!=null){
            setToken(res.data.token)
            commit('SET_TOKEN', res.data.token)
            localStorage.setItem("tku",res.data.adminUser);
            localStorage.setItem("nickName",res.data.name);
            localStorage.setItem("userName",res.data.userName);
          }
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo({commit, state}) {
      return new Promise((resolve, reject) => {
        var res = {
          "msg": "操作成功",
          "code": 200,
          "permissions": [
            "*:*:*"
          ],
          "roles": [
            "admin"
          ],
          "user": {
            "params": {},
            "userName": sessionStorage.getItem("userName"),
            "nickName": sessionStorage.getItem("nickName"),
            "avatar": "",
            "roles": [
              // {
              //   "searchValue": null,
              //   "createBy": null,
              //   "createTime": null,
              //   "updateBy": null,
              //   "updateTime": null,
              //   "remark": null,
              //   "params": {},
              //   "roleId": 1,
              //   "roleName": "超级管理员",
              //   "roleKey": "admin",
              //   "roleSort": "1",
              //   "dataScope": "1",
              //   "menuCheckStrictly": false,
              //   "deptCheckStrictly": false,
              //   "status": "0",
              //   "delFlag": null,
              //   "flag": false,
              //   "menuIds": null,
              //   "deptIds": null,
              //   "admin": true
              // }
            ],
            "roleIds": null,
            "postIds": null,
            "roleId": null,
            "admin": true
          }
        };
        const user = res.user
        const avatar = user.avatar == "" ? require("@/assets/images/profile.jpg") : process.env.VUE_APP_BASE_API + user.avatar;
        if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
          commit('SET_ROLES', res.roles)
          commit('SET_PERMISSIONS', res.permissions)
        } else {
          commit('SET_ROLES', ['ROLE_DEFAULT'])
        }
        commit('SET_NAME', user.userName)
        commit('SET_AVATAR', avatar)
        resolve(res)
        // getInfo().then(res => {
        //   const user = res.user
        //   const avatar = user.avatar == "" ? require("@/assets/images/profile.jpg") : process.env.VUE_APP_BASE_API + user.avatar;
        //   if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
        //     commit('SET_ROLES', res.roles)
        //     commit('SET_PERMISSIONS', res.permissions)
        //   } else {
        //     commit('SET_ROLES', ['ROLE_DEFAULT'])
        //   }
        //   commit('SET_NAME', user.userName)
        //   commit('SET_AVATAR', avatar)
        //   resolve(res)
        // }).catch(error => {
        //   reject(error)
        // })
      })
    },

    // 退出系统
    LogOut({commit, state}) {
      return new Promise((resolve, reject) => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        commit('SET_PERMISSIONS', [])
        removeToken()
        resolve()
        // logout(state.token).then(() => {
        //   commit('SET_TOKEN', '')
        //   commit('SET_ROLES', [])
        //   commit('SET_PERMISSIONS', [])
        //   removeToken()
        //   resolve()
        // }).catch(error => {
        //   reject(error)
        // })
      })
    },

    // 前端 登出
    FedLogOut({commit}) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    }
  }
}

export default user
